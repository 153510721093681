<!-- SPDX-License-Identifier: Apache-2.0 -->
<style scoped>
.border-radius-50 {
  border-radius: 50%;
  width: 15px;
}
</style>
<template>
    <img alt="spinner" class="border-radius-50" src="/static_frontend/images/vendor/internet_nl/probe-animation.gif" >
</template>
<script>
export default {
}
</script>